














































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import PageTitle from "@/components/General/PageTitle.vue";
import { Category as CategoryInterface } from "@/models/category.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";
import { Status } from "@/models/status.interface";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import Category from "@/components/Layout/Common/Category.vue";
import FilePicker from "@/components/Input/FilePicker.vue";
import IconsPicker from "@/components/General/IconsPicker.vue";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: { PageTitle, Loader, Empty, Category, FilePicker, IconsPicker },
})
export default class Categories extends mixins(FormValidations, StyleCheck) {
  iconsUrl = process.env.VUE_APP_ICONS_URL;
  $refs!: {
    categoryForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  dialog = false;
  dialogDelete = false;
  dialogPreview = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  defaultCategory: CategoryInterface = {
    id: undefined,
    name: "",
    icon: "",
    fk_category: undefined,
    category: undefined,
    fk_file: undefined,
    file: undefined,
    status: {
      name: "",
    },
  };
  category: CategoryInterface = { ...this.defaultCategory };
  categoryFile: File | null = null;
  filePreview = "";
  search = null;

  private getIcon(value: any) {
    if (value !== null) {
      this.category.icon = value.name;
    }
  }

  private get categories(): CategoryInterface[] {
    let response = this.$store.getters["categories/getCategories"];
    if (response?.categories) {
      return response.categories;
    } else {
      return [];
    }
  }

  private get categoryProgress(): boolean {
    return this.$store.getters["categories/getCategoryProgress"];
  }

  private get filter() {
    return (item: CategoryInterface, search: string) =>
      item["name"].toUpperCase().indexOf(search.toUpperCase()) > -1 ||
      (item.status && item.status?.name.toUpperCase() == search.toUpperCase());
  }

  private categoryDisabled(category: CategoryInterface): boolean {
    return category.status?.name !== this.$constants.STATUS.ACTIVE;
  }

  private allowEditCategoryStatus(category: CategoryInterface): boolean {
    return (
      category.id !== undefined &&
      (!category.category ||
        (category.category as CategoryInterface).status?.name ===
          this.$constants.STATUS.ACTIVE)
    );
  }

  private created() {
    this.getCategories();
    this.resetCategoryProgress();
  }

  private async getCategories() {
    this.loader = true;
    await this.$store
      .dispatch("categories/getCategories")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  private async resetCategoryProgress() {
    await this.$store.dispatch("categories/resetCategoryProgress");
  }

  private addCategory() {
    try {
      this.clearDialog();
      this.resetValidations();
      this.dialog = true;
    } catch {
      this.clearDialog();
      this.dialog = true;
    }
  }

  private addSubCategory(category: CategoryInterface) {
    try {
      this.clearDialog();
      this.resetValidations();
      this.category.category = { ...category };
      this.dialog = true;
    } catch {
      this.clearDialog();
      this.category.category = { ...category };
      this.dialog = true;
    }
  }

  private updateCategory(category: CategoryInterface) {
    this.clearDialog();
    this.category = { ...category };
    this.category.status = { ...category.status } as Status;
    this.category.category = category.category;
    this.filePreview = category.file ? category.file.url : "";
    this.dialog = true;
  }

  private deleteCategoryConfirm(category: CategoryInterface) {
    this.category = { ...category };
    this.dialogDelete = true;
  }

  private get formTitle() {
    return !this.category.id
      ? this.$tc("Categories.createTitle")
      : this.$tc("Categories.editTitle");
  }

  private clearDialog() {
    this.category = Object.assign(this.category, this.defaultCategory);
    this.categoryFile = null;
    this.filePreview = "";
    this.resetCategoryProgress();
  }

  private openDialogPreview(category: CategoryInterface) {
    this.category = { ...category };
    this.dialogPreview = true;
  }

  private closeDialogPreview() {
    this.category = Object.assign(this.category, this.defaultCategory);
    this.dialogPreview = false;
  }

  resetValidations() {
    this.resetFormValidations([this.$refs.categoryForm]);
  }

  @Watch("dialog")
  private watchDialog(val) {
    val || this.close();
  }

  @Watch("dialogDelete")
  private watchDialogDelete(val) {
    val || this.closeDelete();
  }

  @Watch("categoryFile")
  previewFile(file: File): void {
    if (file != null) {
      this.filePreview = URL.createObjectURL(file);
    } else {
      if (!this.category.id) {
        this.filePreview = "";
        this.categoryFile = null;
      }
    }
  }

  private close() {
    this.dialog = false;
    this.resetValidations();
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.category = Object.assign(this.category, this.defaultCategory);
  }

  private async submit() {
    this.loading = true;

    const payloadCategory = new FormData();
    let category = { ...this.category };
    if (category.category) {
      category.category = (category.category as CategoryInterface).id;
    }
    payloadCategory.append("DATA", JSON.stringify({ category: category }));
    if (this.categoryFile) {
      payloadCategory.append(
        this.$constants.FILE_CATEGORY.CATEGORY,
        this.categoryFile as File
      );
    }
    if (!category.id) {
      if (
        (category.category && category.name && category.icon) ||
        (!category.category &&
          category.name &&
          category.icon &&
          this.categoryFile)
      ) {
        await this.$store
          .dispatch("categories/createCategory", payloadCategory)
          .then(() => {
            const Success: Notification = {
              message: this.$tc("Categories.success"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: false,
            };

            this.$store.dispatch("notifications/showNotification", Success);
            this.close();
            this.getCategories();
          })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Categories.error"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$refs.categoryForm.validate();
      }
    } else {
      await this.$store
        .dispatch("categories/updateCategory", {
          data: payloadCategory,
          id: category.id,
        })
        .then(async () => {
          await this.$store.dispatch("categories/updateCategoryStatus", {
            id: this.category.id as number,
            status: this.category.status?.name,
          });
          const Success: Notification = {
            message: this.$tc("Categories.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.close();
          this.getCategories();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Categories.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.loading = false;
  }

  private async deleteCategory() {
    this.loading = true;
    await this.$store
      .dispatch("categories/updateCategoryStatus", {
        id: this.category.id as number,
        status: this.$constants.STATUS.DELETED,
      })
      .then(() => {
        const Success: Notification = {
          message: this.$tc("Categories.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        this.closeDelete();
        this.getCategories();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
